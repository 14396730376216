<template>
	<div>
		<h1>Klavierstimmer<span>Andreas Schneider</span></h1>
		<figure>
			<img src="@/../public/img/piano.jpg" alt="Klavierstimmer Andreas Schneider" />
		</figure>
		<p>Ihr kompetenter Stimmservice in der Region Vorderpfalz</p>
	</div>
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'Home',
		components: {}
	};
</script>

<style lang="scss" scoped>
	h1 {
		text-shadow: 0px 0px 8px #f9aa00b5;
		text-decoration: none;
		margin-bottom: 0;
		font-weight: normal;
		line-height: 1;
		border-bottom: none;

		span {
			display: block;
			font-size: 59px;
			margin-left: 100px;
			text-shadow: 0px 0px 8px #d2080887;
		}
	}

	p {
		font-family: ltzapfino_oneregular;
		font-size: 34px;
	}

	img {
		width: 100%;
	}
</style>
