<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@media screen and (min-width: $mobile +1) {
		nav {
			min-width: 150px;
			transition: transform 0.25s ease-out;

			&:before {
				content: '';
				position: fixed;
				left: 0;
				top: 0;
				height: 100%;
				width: 110px;
				background-color: #8a0a0e;
				z-index: -2;
			}

			ul {
				display: flex;
				flex-direction: column;

				li {
					& + li {
						margin-top: 10px;
					}

					a {
						position: relative;
						display: block;
						padding: 3px 10px;
						text-decoration: none;
						color: #fff;
						list-style: none;
						background: #ccc;
						border-top-right-radius: 15px;
						background-color: $orange;
						transition: all 0.25s ease-out;
						// overflow: hidden;
						font-family: Arial;

						&[aria-current],
						&:hover {
							background-color: $grey;

							&::after {
								background-color: $orange;
							}
						}

						&::after {
							content: '';
							position: absolute;
							bottom: -4px;
							left: 0;
							width: 90%;
							height: 4px;
							background-color: black;
							transition: all 0.25s ease-out;
						}
					}
				}
			}
		}
	}

	.fab {
		position: fixed;
		bottom: 20px;
		left: -100%;
		width: 30px;
		cursor: pointer;
		transition: all 0.25s ease-out;

		span {
			display: block;
			width: 100%;
			height: 4px;
			background-color: #333;
			border-radius: 5px;
			transition: all 0.25s ease-out;

			& + span {
				margin-top: 4px;
			}
		}
	}

	@media screen and (max-width: $mobile) {
		nav {
			ul {
				position: fixed;
				bottom: 60px;
				left: 0;
				transform: translateX(-100%);
				transition: all 0.25s ease-out;
				background-color: #333;
				opacity: 0.9;
				border-radius: 10px;
				padding: 25px;

				li + li {
					margin-top: 10px;
				}

				a {
					color: #fff;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.fab {
			left: 20px;

			&:hover {
				span {
					background-color: $orange;
				}
			}
		}

		.open {
			ul {
				left: 20px;
				transform: translateX(0);
			}

			.fab {
				span {
					&:nth-child(1) {
						transform: rotate(45deg) translate(3px, 8.5px);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						transform: rotate(-45deg) translate(3px, -8.5px);
					}
				}
			}
		}
	}
</style>

<script>
	export default {
		name: 'Navigation',
		directives: {
			'click-outside': {
				beforeMount: function (el, binding) {
					// Define ourClickEventHandler
					const ourClickEventHandler = (event) => {
						if (!el.contains(event.target) && el !== event.target) {
							// as we are attaching an click event listern to the document (below)
							// ensure the events target is outside the element or a child of it
							binding.value(event); // before binding it
						}
					};
					// attached the handler to the element so we can remove it later easily
					el.__vueClickEventHandler__ = ourClickEventHandler;

					// attaching ourClickEventHandler to a listener on the document here
					document.addEventListener('click', ourClickEventHandler);
				},
				unmounted: function (el) {
					// Remove Event Listener
					document.removeEventListener('click', el.__vueClickEventHandler__);
				}
			}
		},
		methods: {
			openMenu () {
				this.$el.classList.toggle('open');
			},
			onClickOutside () {
				this.$el.classList.remove('open');
			}
		}
	};
</script>

<template>
	<nav v-click-outside="onClickOutside">
		<div class="fab" @click="openMenu">
			<span></span>
			<span></span>
			<span></span>
		</div>
		<ul>
			<li>
				<router-link to="/">Home</router-link>
			</li>
			<li>
				<router-link to="/vorstellung">Vorstellung</router-link>
			</li>
			<li>
				<router-link to="/klavierstimmen">Klavierstimmen</router-link>
			</li>
			<li>
				<router-link to="/impressionen">Impressionen</router-link>
			</li>
			<li>
				<router-link to="/services">Services</router-link>
			</li>
			<li>
				<router-link to="/links">Links</router-link>
			</li>
			<li>
				<router-link to="/kontakt">Kontakt</router-link>
			</li>
			<li>
				<router-link to="/impressum">Impressum</router-link>
			</li>
		</ul>
	</nav>
</template>
