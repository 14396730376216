import { render } from "./App.vue?vue&type=template&id=3fcba5d0&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"

import "@/assets/common/reset.css?vue&type=style&index=0&lang=css"
import "./App.vue?vue&type=style&index=1&id=3fcba5d0&lang=scss&scoped=true"
import "./App.vue?vue&type=style&index=2&id=3fcba5d0&lang=scss"
script.render = render
script.__scopeId = "data-v-3fcba5d0"

export default script