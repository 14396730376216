import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vorstellung',
    name: 'Vorstellung',
    // route level code-splitting
    // this generates a separate chunk (Vorstellung.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Vorstellung" */ '../views/Vorstellung.vue')
  },
  {
    path: '/klavierstimmen',
    name: 'Klavierstimmen',
    // route level code-splitting
    // this generates a separate chunk (Klavierstimmen.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Klavierstimmen" */ '../views/Klavierstimmen.vue'
      )
  },
  {
    path: '/impressionen',
    name: 'Impressionen',
    // route level code-splitting
    // this generates a separate chunk (Impressionen.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Impressionen" */ '../views/Impressionen.vue')
  },
  {
    path: '/zubehoer',
    name: 'Zubehoer',
    // route level code-splitting
    // this generates a separate chunk (Zubehoer.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Zubehoer" */ '../views/Zubehoer.vue')
  },
  {
    path: '/services',
    name: 'Services',
    // route level code-splitting
    // this generates a separate chunk (Services.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Services" */ '../views/Services.vue')
  },
  {
    path: '/anfahrt',
    name: 'Anfahrt',
    // route level code-splitting
    // this generates a separate chunk (Anfahrt.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Anfahrt" */ '../views/Anfahrt.vue')
  },
  {
    path: '/links',
    name: 'Links',
    // route level code-splitting
    // this generates a separate chunk (Links.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Links" */ '../views/Links.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    // route level code-splitting
    // this generates a separate chunk (Kontakt.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Kontakt" */ '../views/Kontakt.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (Impressum.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Impressum" */ '../views/Impressum.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
